/*global
 CookieControl
 scriptData
*/

import { CookieControlDispatcher } from './cookie-control-dispatcher.class';
import { docCookies } from './vendor/cookies.js';

const $ = jQuery;

/*
 * Banner markup/styling
 */

const styles = `<style type="text/css">
.CookieControlBanner {
	background-color: #414042;
	color: #fff;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2147483647;
}
.CookieControlBanner-message {
	flex-grow: 1;
	padding-right: 20px;
	width: calc(100% - 350px);
}
.CookieControlBanner-buttonWrap {
	display: flex;
	align-items: center;
}
.CookieControlBanner-buttonWrap *:first-child {
	margin-right: 20px;
}
.CookieControlBanner-settingsButton {
	background-color: #016dcd !important;
	border-color: #016dcd !important;
	color: #fff !important;
}
.CookieControlBanner-acceptButton {
	background-color: #6CC04A !important;
	border-color: #6CC04A !important;
	color: #fff !important;
	display: none;
}
.CookieControlBanner-dismissButton {
	background-color: #6CC04A !important;
	border-color: #6CC04A !important;
	color: #fff !important;
}
@media (max-width: 767px) {
	.CookieControlBanner {
		display: block;
	}
	.CookieControlBanner-message {
		margin-bottom: 14px;
		width: 100%;
	}
	.CookieControlBanner-buttonWrap {
		justify-content: center;
	}
}
</style>`
const bannerText = 'We have placed cookies on your device to help make this website better. You can use this tool to change your cookie settings. Otherwise, we’ll assume you’re OK to continue.';
const privacyURL = scriptData.cookie_control_policy_page;
const privacyLink = typeof(privacyURL) === 'string' && privacyURL.substr(0, 4) === 'http' ? privacyURL : '/'+privacyURL;
const tmpl = `${styles}
<div class="CookieControlBanner">
	<div class="CookieControlBanner-message">${scriptData.cookie_control_banner_message || bannerText} <a href="${privacyLink}">Privacy policy</a></div>
	<div class="CookieControlBanner-buttonWrap">
		<button class="CookieControlBanner-button CookieControlBanner-settingsButton">Cookie settings</button>
		<button class="CookieControlBanner-button CookieControlBanner-acceptButton">Accept all cookies</button>
		<button class="CookieControlBanner-button CookieControlBanner-dismissButton">I'm fine with this</button>
	</div>
</div>`;

/*
 * Banner handler class
 */

export class CookieControlBanner {

	constructor() {

		this.cookieName = '_GDPR_cookie_banner_shown';

		let noticeShown = docCookies.getItem(this.cookieName) !== null;

		CookieControlDispatcher.dispatch();

		if (!noticeShown) {
			$(document).ready(this.render.bind(this));
		}

		// Intercept CookieControl close button click
		$('body').on('click', '#ccc-close', this.acceptCookieSettings.bind(this));
		// Intercept CookieControl panel toggle (when closing panel)
		$('body').on('click', '#ccc:not([open]) #ccc-icon', this.acceptCookieSettings.bind(this));

	}

	render() {
		// add cookie banner to DOM
		this.$el = $(tmpl);
		$('body').append(this.$el);

		// bind button events
		this.$el.find('.CookieControlBanner-settingsButton').on('click', this.openSettings.bind(this));
		this.$el.find('.CookieControlBanner-acceptButton').on('click', this.acceptAllCookies.bind(this));
		this.$el.find('.CookieControlBanner-dismissButton').on('click', this.destroyBanner.bind(this));
	}

	// Actions
	////

	openSettings(e) {
		// destroy banner
		this.destroyBanner();
		// open CookieControl panel
		CookieControl.open();
	}

	acceptAllCookies() {
		// toggle all categories
		// Object.keys(CookieControl.config().optionalCookies).map( CookieControl.toggleCategory );

		CookieControl.config().optionalCookies.forEach((obj, idx) => {
			if (document.cookie.indexOf(obj.name) > -1) return;
			CookieControl.toggleCategory(idx);
		});

		// fire regular accept call
		this.acceptCookieSettings();
	}

	acceptCookieSettings() {
		// destroy banner
		this.destroyBanner();
		// Dispatch cookie control preferences
		CookieControlDispatcher.dispatch();
	}

	destroyBanner() {
		// set cookie shown notice
		docCookies.setItem(this.cookieName, 1, Infinity, '/');
		// destroy banner
		this.$el && this.$el.remove();
	}

}
