/*global
  scriptData
*/

import { CookieControlDispatcher } from './cookie-control-dispatcher.class';

// TODO: add checks for api key & scriptData existing

window.cookieControlConfig = {
  apiKey: scriptData.cookie_control_api_key,
  product: 'COMMUNITY',
  necessaryCookies: [
    'performance-revoked',
    'functionality-revoked',
    'targeting-or-advertising-revoked',
    '__CFDUID', // Cloudflare security cookie
    '_GDPR_cookie_banner_shown',
    '__stripe_mid',
    '__stripe_sid',
    'DYNSRV',
    'JSESSIONID',
    'NID',
    'PHPSESSID',
    'PYPF', // Paypal
    'wordfence_*',
    'wf*',
    'wc_*',
    'woo*',
    'wp_woo*'
    // 'word*',
    // 'wp-*'
  ],
  optionalCookies: [
    {
      name: 'performance',
      label: 'Performance',
      description: 'These cookies collect information about how visitors use a website and are used to provide better quality content and functionality. All information these cookies collect is aggregated and only used for statistical purposes.',
      cookies: [],
      initialConsentState: 'on',
      onAccept : function() {
      	CookieControlDispatcher.registerConsent('performance', true);
      },
      onRevoke: function() {
      	CookieControlDispatcher.registerConsent('performance', false);
      }
    },
    {
      name: 'functionality',
      label: 'Functionality',
      description: 'These cookies allow the website to remember choices you make (such as your user name, language or the region you are in) and provide enhanced, more personal features. The information these cookies collect may be anonymised and they cannot track your browsing activity on other websites.',
      cookies: [],
      initialConsentState: 'on',
      onAccept : function() {
      	CookieControlDispatcher.registerConsent('functionality', true);
      },
      onRevoke: function() {
      	CookieControlDispatcher.registerConsent('functionality', false);
      }
    },
    {
      name: 'targeting-or-advertising',
      label: 'Targeting or Advertising',
      description: 'These types of cookies are set by digital advertising businesses for the prime or sole purpose of managing the performance of adverts, displaying adverts, and/or building user profiles to determine the display of adverts elsewhere.',
      cookies: [],
      initialConsentState: 'on',
      onAccept : function() {
      	CookieControlDispatcher.registerConsent('targeting-or-advertising', true);
      },
      onRevoke: function() {
      	CookieControlDispatcher.registerConsent('targeting-or-advertising', false);
      }
    },
  ],
  position: 'RIGHT',
  theme: 'DARK',
};
