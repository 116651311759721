/*
 * Cookie Control Dispatcher
 * - Designed to work alongside Civic CookieControl
 * - Buffers & dispatches consents as js events for easier integration
 */

export class CookieControlDispatcher {

	static init() {
		if(this.initialised) return;

		// initialise static properties
		this.currentConsents = {};
		this.newConsents = {};

		this.initialised = true;
	}

	static registerConsent(category, bool) {
		this.newConsents[category] = bool;
	}

	static dispatch() {

		// Dispatch events for each changed consent
		for(let [category, value] of Object.entries(this.newConsents)) {

			const previousValue  = this.currentConsents[category];
			const consentChanged = value !== previousValue;
			// const triggerEvent   = consentChanged && !(previousValue === undefined && value === false);

			if(consentChanged) {
				// trigger CookieControl event
				// if(triggerEvent) {
					jQuery('body').trigger(`CookieControl-on${value ? 'Accept' : 'Revoke'}.${category}`);
				// }
				// store given consent value
				this.currentConsents[category] = value;
			}

		}

		// clear newConsents
		this.newConsents = {};

	}
}

// initialise
CookieControlDispatcher.init()
