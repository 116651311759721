/*global
	jQuery, CookieControl
 */

import { CookieControlDispatcher } from './js/cookie-control-dispatcher.class';
import { CookieControlConsent } from './js/cookie-control-consent.class';
import { CookieControlBanner } from './js/cookie-control-banner.class';
import './js/cookie-control-config';

let $ = jQuery;

function wait_for_cookie_control(cb) {
	if($('body').children('#ccc').length) {
		// call callback
		typeof cb == 'function' && cb();
	} else {
		// recurse
		setTimeout(() => wait_for_cookie_control(cb), 50);
	}
}

// set a timeout so other scripts can modify cookieControlConfig before it's executed
setTimeout(() => {

	// Load cookie control
  CookieControl.load( window.cookieControlConfig );
  // ensure CookieControl starts hidden
  CookieControl.hide();

  //  wait for CookieControl to finish
  wait_for_cookie_control(() => {
  	// Construct CookieControlBanner
  	new CookieControlBanner();
		$('body').trigger('ccc-loaded');
  });

}, 1);

window.CookieControlDispatcher = CookieControlDispatcher;
window.CookieControlConsent = CookieControlConsent;
window.CookieControlBanner = CookieControlBanner;
